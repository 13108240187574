<template>
  <div>
    <a-form-model layout="inline">
      <a-form-model-item label="">
        <a-input v-model="formInline.task_num" placeholder="请输入报告编号" class="w200"
                 @keyup="Get_examining_reportpage"></a-input>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input v-model="formInline.name" placeholder="请输入委托单名称" class="w200"
                 @keyup="Get_examining_reportpage"></a-input>
      </a-form-model-item>

      <a-form-model-item label="">
        <a-select v-model="formInline.examining_type" placeholder="请选择" class="w200"
                  @change="Get_examining_reportpage">
          <a-select-option value="">全部类别</a-select-option>
          <a-select-option value="1">委托检测</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="时间">
        <a-date-picker v-model="formInline.starttime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
        至
        <a-date-picker v-model="formInline.endtime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                       @change="Get_examining_reportpage"/>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-button type="danger" @click="Clear()" class="ml10">清空搜索</a-button>
      </a-form-model-item>

    </a-form-model>
    <a-space style="margin-top: 10px;" class="btner">
      <div class="btnerlist" @click="showDrawer1">
        <i class="iconfont icon-rizhi" style="font-size: 16px"></i> 操作日志
      </div>
      <div class="btnerlist" @click="urlJump()">
        <i class="iconfont icon-jianyanbaogao" style="font-size: 14px"></i> 查看报告
      </div>
      <div v-if="level == 2" class="btnerlist" @click="callbackclick()">
        <i class="iconfont icon-jianyanbaogao" style="font-size: 14px"></i> 委派回执
      </div>
    </a-space>
    <table class="layui-table" lay-size="sm" style="margin-top: -9px">
      <thead>
      <tr>
        <th>
          <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
        </th>
        <th @click="paixuclickup(1)" style="cursor: pointer; ">
          <span>报告编号</span>
          <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 1 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 1 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
          </div>
        </th>
        <th @click="paixuclickup(2)" style="cursor: pointer; ">
          <span>委托单名称</span>
          <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 2 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 2 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
          </div>
        </th>
        <th @click="paixuclickup(3)" style="cursor: pointer; ">
          <span>单位名称</span>
          <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 3 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 3 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
          </div>
        </th>
        <th @click="paixuclickup(4)" style="cursor: pointer; ">
          <span>执行标准分类</span>
          <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 4 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 4 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
          </div>
        </th>
        <th @click="paixuclickup(5)" style="cursor: pointer; ">
          <span>检测类别</span>
          <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 5 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 5 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
          </div>
        </th>
        <th @click="paixuclickup(6)" style="cursor: pointer; ">
          <span>状态</span>
          <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 6 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 6 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
          </div>
        </th>
        <th @click="paixuclickup(7)" style="cursor: pointer; ">
          <span>检测项目</span>
          <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 7 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 7 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
          </div>
        </th>
        <th>校验退回</th>
        <th>审核退回</th>
        <th>编制人</th>
        <th>编制时间</th>
        <th>结果最后修改时间</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,key) in Examining_reportdata" :key="key" :class="[item.check ? 'active':'']"
          @click="clickrow(key)">
        <td>
          <a-checkbox v-model='item.check' @change="itemActiveChange"></a-checkbox>
        </td>
        <td>{{ item.task_num }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.com_name }}</td>
        <td>{{ item.det_item_str }}</td>
        <td>{{ item.examining_type_trun }}</td>
        <td style="cursor: pointer">
          <span v-if="item.check_num>0 || item.auditor_num>0" style="color: #ff2929;">{{ item.state_turn }}</span>
          <span v-else>{{ item.state_turn }}</span><br>
          <span style="color: #ff2929;" v-if="item.state===4 ||item.state===6">( 原因：{{ item.remark }})</span>
        </td>
        <td>{{ item.check_item_num }} 项</td>
        <td>{{ item.check_num }} <span v-if="item.check_num===null">0</span> 次</td>
        <td>{{ item.auditor_num }}<span v-if="item.auditor_num===null">0</span> 次</td>
        <td>{{ item.dandan_edit_name }}</td>
        <td>{{ item.create_time }}</td>
        <td>{{ item.update_time }}</td>
      </tr>
      </tbody>
    </table>
    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>

    <!--历史弹窗-->
    <div v-if="edit_info1">
      <Historylist :edit_time="edit_time1" :edit_info="edit_info1" :close="onCloseDrawer1"
                   :visible="drawer_visible1"></Historylist>
    </div>

    <a-modal v-model:visible="tasklist_show" title="下发委托任务" :footer="null">
      <div>
        <div>
          <a-input v-model="item_active"></a-input>
        </div>
        <table class="layui-table" lay-size="sm">
          <thead>
          <tr>
            <th>任务内容</th>
            <th>任务状态</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,key) in tasklist" :key="key">
            <td v-html="contentTurn(item.content)"></td>
            <td>
              <div v-if="Number(item.status) == 1">待处理</div>
              <div v-if="Number(item.status) == 2">已接收</div>
              <div v-if="Number(item.status) == 3">已完成</div>
            </td>
            <td>
              <a-button @click="createTaskList(item.id)" type="primary">回复该任务</a-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-modal>
  </div>
</template>
<script>

import Historylist from '../baogaolistshenhe/lishilist/lishilist.vue';

export default {
  name: "baogaolist",
  components: {
    Historylist
  },
  data() {
    return {
      level: app_config.level,
      tasklist: [],
      tasklist_id: 0,
      tasklist_show: false,
      item_active: '',

      xq_item: false,
      nor_item: false,
      selectdata: false,
      drawer_visible: false,
      drawer_visible1: false,
      formInline: {
        task_num: '',
        name: '',
        examining_type: '',
        state: '',
        starttime: '',
        endtime: '',
      },
      methodtypes: [],
      visible: false,
      visible1: false,
      value: '',
      Sourcerecorddata: [],
      jiaoyanno: {
        remark: ''
      },
      edit_time1: 0,
      edit_info1: false,
      edit_time: 0,
      edit_info: false,
      all_check: false,
      activedata: '',
      page: 1,
      count: 0,
      pagesize: 0,
      Examining_reportdata: [],
      page_show: false,
      data_name: 0,
      data_sort: 'asc',
    }
  },
  mounted() {
    this.mountedDo()

  },

  watch: {

    formInline: {
      handler() {
        if (this.page_show) this.searchClick()
      },
      deep: true
    },
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    },

    itemActiveChange() {
      let do_ = true
      this.Examining_reportdata.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.all_check = do_
    },

    allChange(e) {
      this.Examining_reportdata.map((item, key) => {
        this.$set(this.Examining_reportdata[key], 'check', this.all_check)
      })
    },

  },
  methods: {
    contentTurn(content) {
      return content.split("\n").join("<br/>");
    },
    createTaskList(id) {
      this.$sa0.post({
        url: this.$api('二级：保存委托任务'),
        data: {
          callback: this.item_active,
          id: id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('回复成功')
            this.tasklist_show = false
            this.tasklist_show()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    mountedDo() {
      this.formInline.task_num = this.$route.query.task_num || '';
      this.formInline.state = this.$route.query.state || '';
      this.Get_examining_report()
      setTimeout(() => {
        this.page_show = true
      }, 1)
    },
    gettasklist() {
      this.$sa0.post({
        url: this.$api('二级：委托任务列表2'),
        data: {},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tasklist = response.data.list
            this.tasklist_show = true
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    callbackclick() {
      let id = 0
      let count = 0
      let info = 0
      this.Examining_reportdata.map((item, key) => {
        if (item.check) {
          id = item.id
          count++
          info = item
        }
      })
      console.log(id)
      if (!id) return layer.msg('请选择检验报告')
      if (count !== 1) return layer.msg('只能选择一个报告进行查看')
      this.item_active = info.pdf_url
      this.gettasklist()
    },
    urlJump(pdf_url) {
      let id = 0
      let count = 0
      let info = 0
      this.Examining_reportdata.map((item, key) => {
        if (item.check) {
          id = item.id
          count++
          info = item
        }
      })
      console.log(id)
      if (!id) return layer.msg('请选择检验报告')
      if (count !== 1) return layer.msg('只能选择一个报告进行查看')
      // if (info.state_turn === "待编制") {
      //     layer.msg("还没有编制报告，请先编制报告")
      // } else
      window.open(info.pdf_url,)
    },

    searchClick() {
      this.$router.push({
        query: {
          task_num: this.formInline.task_num,
          state: this.formInline.state,
        }
      })
    },
    clickrow(key) {
      this.$set(this.Examining_reportdata[key], 'check', !this.Examining_reportdata[key].check)
      this.itemActiveChange()
    },

    itemActiveChange() {
      let do_ = true
      this.Examining_reportdata.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.all_check = do_
    },

    allChange(e) {
      this.Examining_reportdata.map((item, key) => {
        this.$set(this.Examining_reportdata[key], 'check', this.all_check)
      })
    },

    Get_examining_reportpage() {
      this.page = 1
      this.Get_examining_report()
    },

    // 获取列表
    Get_examining_report() {
      this.$sa0.post({
        url: this.$api('Get_examining_report'),
        data: {
          page: this.page,
          task_num: this.formInline.task_num,
          name: this.formInline.name,
          examining_type: this.formInline.examining_type,
          state: 5,
          baogao_type: 1,
          starttime: this.formInline.starttime,
          endtime: this.formInline.endtime,
          type: 2
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Examining_reportdata = response.data.result.list
            this.count = response.data.result.count;//分页
            this.pagesize = response.data.result.pagesize;//分页
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    paixuclickup(ordertype) {
      if (this.data_name !== ordertype) {
        this.data_name = ordertype
        this.data_sort = 'asc'
      } else {
        if (this.data_sort === 'asc') {
          this.data_sort = 'desc'
        } else {
          this.data_sort = 'asc'
        }
      }
      this.$sa0.post({
        url: this.$api('Get_examining_report'),
        data: {
          page: this.page,
          task_num: this.formInline.task_num,
          name: this.formInline.name,
          examining_type: this.formInline.examining_type,
          state: 5,
          baogao_type: 1,
          starttime: this.formInline.starttime,
          endtime: this.formInline.endtime,
          type: 2,
          data_name: this.data_name,
          data_sort: this.data_sort,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Examining_reportdata = response.data.result.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    onChange(page) {
      this.page = page;
      this.Get_examining_report() //获取列表记录
    },

    // 清空搜索
    Clear() {
      this.formInline.task_num = '';
      this.formInline.name = '';
      this.formInline.examining_type = '';
      this.formInline.state = '';
      this.formInline.starttime = '';
      this.formInline.endtime = '';
      this.Get_examining_report();
    },


    Search() {
      this.Get_examining_report();
    },


    // // 定义 关闭抽屉时的 函数
    // onCloseDrawer() {
    //     this.drawer_visible = false;
    //     this.Get_examining_report()
    // },
    // // 定义 打开抽屉时的 函数
    // showDrawer(item) {
    //     let selectdata = []
    //     this.Examining_reportdata.map((item) => {
    //         console.log(item)
    //         if (item.check) {
    //             selectdata.push(item)
    //         }
    //     })
    //
    //     if (selectdata.length < 1) {
    //         layer.msg('请勾选需要操作的委托单')
    //         return
    //     }
    //
    //     this.selectdata = false
    //     setTimeout(() => {
    //         this.edit_time = new Date() / 1;
    //         this.selectdata = selectdata;
    //         this.drawer_visible = true;
    //     })
    //     console.log(11111, selectdata)
    //
    // },
    // 日志定义 关闭抽屉时的 函数
    onCloseDrawer1() {
      this.drawer_visible1 = false;
      this.Get_examining_report()
    },
    // 日志定义 打开抽屉时的 函数
    showDrawer1(item) {
      let id = 0
      let count = 0
      this.Examining_reportdata.map((item, key) => {
        if (item.check) {
          this.edit_info1 = item;
          id = item.id
          count++
        }
      })
      if (!id) return layer.msg('请选择报告')
      if (count !== 1) return layer.msg('只能选择一个报告')

      setTimeout(() => {
        this.edit_time1 = new Date() / 1;
        this.drawer_visible1 = true;
      })
    }

  }
}
</script>

<style scoped>
.w100 {
  width: 100px
}

.w200 {
  width: 200px
}

.ml10 {
  margin-left: 10px
}

.baogaomain {
  width: 100%;
  background: #f9f9f9;
  margin-top: 20px;
  overflow-y: auto
}

.nodata {
  font-size: 20px;
  text-align: center;
  padding-top: 50px;
  font-weight: bold;
}

.btner {
  width: 100%;
  background: #f9f9f9;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
}

.active {
  background: #eff8ff;
}

.btnerlist {
  cursor: pointer;
  border-right: 1px solid #eeeeee;
  height: 26px;
  line-height: 26px;
  padding: 0 10px
}

.btnerlist :last-child(1) {
  border: none
}

.fcadd {
  color: #66c14c !important;
  font-size: 18px !important;
}

.btnerlist i {
  color: #999999;
  font-size: 14px;
  vertical-align: bottom;
}

.btnerlist:hover {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;

}

.btnerlist:hover i, .btnerlist:hover.fcadd {
  color: #ffffff !important
}

.isthis {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;
}

.isthis i {
  color: #ffffff
}

table tr {
  cursor: pointer
}

.ant-table-column-sorter {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 5px;
  cursor: pointer;
  width: 10px;
  vertical-align: middle;
}

.jiantou {
  color: #999999
}

.classname {
  color: #1890ff;
}

.fl {
  float: left
}

.active {
  background: #ebf6ff
}

table tr {
  cursor: pointer
}

</style>
